import { ISubscribedOrg } from "shared/providers/context/types/auth-context";
import { TypeFeatures } from "shared/utils/hooks/useFeature";

interface IPixel {
  content_id: number;
  content_type: number;
  created_at: string;
  google_conversion_label: string;
  id: number;
  pixel_code: string;
  status: string;
  track_boleto: boolean;
  track_checkout: boolean;
  track_credit_card: boolean;
  track_view_content: boolean;
  tracking_level: string;
  type: string;
  updated_at: string;
}

interface ILangs {
  code: string;
  id: number;
  image_url: string;
  name: string;
}

export interface IAdvancedTheme {
  themeEnsinioMark: string;
  themeBlogThumbnails: string;
  themeBundleThumbnails: string;
  themeBuyButtonsColor: string;
  themeBuyButtonsOption: string;
  themeCategoryThumbnails: string;
  themeComplementaryButtonColor: string;
  themeComplementaryIconColor: string;
  themeComplementaryStyle: string;
  themeCourseProgressColor1: string;
  themeCourseProgressColor2: string;
  themeCourseProgressStyle: string;
  themeCourseThumbnails: string;
  themeHeaderBackgroundColor: string;
  themeHeaderStyle: string;
  themeHeaderTextColor: string;
  themeMediaSpaceThumbnails: string;
  themeNavigationButtonsButtonColor: string;
  themeNavigationButtonsCompletedButtonColor: string;
  themeNavigationButtonsConfig: string;
  themeUserThumbnails: string;
}

export interface IGeneralTheme {
  themeDarkModeSecondaryColor: string;
  themeDarkModeThirdColor: string;
  themeSecondaryColor: string;
  themeThirdColor: string;
  themeOthersShowEnsinioBrand: string;
  themeDarkModeLogoHorizontal: string;
  themeDarkModeLogoIcon: string;
  themeDarkModeMasterColor: string;
  themeDarkModeOptions: "only_theme_option" | "inactive" | "active";
  themeDarkModeTextMasterColor: string;
  themeFavicon: string;
  themeFaviconCustom: string;
  themeLogoHorizontal: string;
  themeLogoIcon: string;
  themeLogoIconOptionsTopBar: string;
  themeLogoIconTopBar: string;
  themeLogoOptionsTopBar: string;
  themeLogoTopBar: string;
  themeMainMenu: string;
  themeMasterColor: string;
  themeMasterTextColor: string;
  themeSideBar: string;
  themeSideBarBackgroundColor: string;
  themeSideBarColapseIconBackgroundColor: string;
  themeSideBarColapseIconColorColor: string;
  themeSideBarItemColor: string;
  themeTopBar: string;
  themeTopBarBackgroundColor: string;
  themeTopBarIconsColor: string;
}
interface ISettings {
  ["advanced-theme"]: IAdvancedTheme;
  blog: any;
  bundles: any;
  collections: any;
  courses: any;
  dashboard: any;
  gamification: any;
  ["general-theme"]: IGeneralTheme;
  ["learning-paths"]: any;
  ["media-space"]: any;
  products: any;
  site: any;
  ["social-network"]: any;
  users: any;
  others: any;
  comments: any;
}

export interface ICustomFieldsActive {
  label: string;
  name: string;
  placeholder: string;
  required: boolean;
}

export interface ICreateAccountOptions {
  custom_fields_active: ICustomFieldsActive[];
  request_phone_to_create_account: boolean;
  request_address_to_create_account: boolean;
  request_document_to_create_account: boolean;
  request_custom_fields_to_create_account: boolean;
}

export type ITenantBillingStatus =
  | "canceled"
  | "pending"
  | "unpaid"
  | "paid"
  | "trial"
  | "finished"
  | "manual";

export interface ITenantBilling {
  billing_status: ITenantBillingStatus;
  plan_name: string;
  plan_slug: string;
  subscription_ends_at: string;
  trial_ends_at: string;
}

export interface TenantData {
  active_anamnese: number;
  active: boolean;
  affiliate_commissions: {
    affiliate_month_percentage: number;
    affiliate_month_recurrence: number;
    affiliate_year_percentage: number;
    affiliate_year_recurrence: number;
  };
  billing: ITenantBilling;
  organization: ISubscribedOrg & {
    showSelectModal: boolean;
    showPageLoader: boolean;
  };
  country: {
    id: number;
    alpha2: string;
    alpha3: string;
    num: string;
    isd: string;
    name: string;
  };
  create_account_options: ICreateAccountOptions;
  created_at: string;
  is_published: boolean;
  site: { siteName: string };
  currency: {
    id: number;
    name: string;
    name_code: string;
    code: number;
    precision: number;
    subunit: number;
    symbol: string;
  };
  email: string;
  full_name: string;
  id: number;
  langs: ILangs[];
  offers: {
    is_enabled: boolean;
    is_checkout_steps_required: boolean;
    is_checkout_onepage_enabled: boolean;
  };
  use_pixel: boolean;
  active_pixels: string[];
  use_conversion_api: boolean;
  pixels: IPixel[];
  settings: ISettings;
  soft_descriptor: string;
  updated_at: string;
  uuid: string;
  enabled_features: string[];
  video_folder?: string;
  subscription_status: string;
  promoters: {
    only_simple_national_opting: any;
    request_nf_to_cnpj_withdrawal: boolean;
  };
  withdrawal_amount: {
    min_value: number;
    promoter_min_amount: number;
  };

  subscribe_now_cta: {
    group_id: number;
    group_slug: string;
    show: boolean;
    text: string;
  };
  plan: {
    ff_transfer: number;
    slug: string;
    name: string;
    id: number;
    features: IPlanFeature[];
    pf_credit_card_interest: number;
  };
  acquirer: {
    complement_min_value: boolean;
    min_value: number;
  };
  has_completed_guide: boolean;

  video_usage: {
    estimated_cost: string;
    last_updated: string;
    storage_limit: number;
    storage_surplus_fee: string;
    storage_usage: string;
    streaming_limit: number;
    streaming_surplus_fee: string;
    streaming_usage: string;
  };
}

interface IPlanFeature {
  created_at: string;
  id: number;
  key: TypeFeatures;
  pivot: { ensinio_plan_id: number; feature_id: number };
  updated_at: string;
  value: 1;
}

const tenancy: TenantData = {} as TenantData;

export default tenancy;
