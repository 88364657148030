import { createContext, useEffect, useState } from "react";

import Router, { useRouter } from "next/router";

import cookie from "js-cookie";
import { parseCookies } from "nookies";

import { removeAlreadyShowedClimbedPodiumModalFromCookies } from "shared/components/molecules/Gamification/utils/climbedPodiumModalUtils";
import { removeAlreadyShowedLostPlaceInPodiumModalFromCookies } from "shared/components/molecules/Gamification/utils/lostPlaceInPodiumModalUtils";
import api from "shared/infra/services/api";
import emailTokenConfirmation from "shared/infra/services/auth/emailTokenConfirmation";
import {
  firstAccessRequest,
  recoverUserInformation,
  registerRequest,
  registerWithoutPasswordRequest,
  resetPasswordRequest,
  signInRequest,
  updateUserRequest,
} from "shared/infra/services/auth/requests-api";
import { useGlobalContext } from "shared/utils/hooks/useGlobalContext";
import useMainPage from "shared/utils/hooks/useMainPage";
import openNotificationWithIcon from "shared/utils/openNotificationWithIcon";

import { useRoleSlug } from "@Console/hooks/useRoleSlug";
import { captureException, captureMessage } from "@sentry/nextjs";

import {
  ICollections,
  IComments,
  IFollowers,
  IFollowing,
  ILastTopics,
} from "./initialStates/user";
import {
  AuthContextData,
  AuthContextProps,
  FirstAccess,
  IUpdateUserProfileInfo,
  RegisterData,
  RegisterWithoutPassword,
  ResetPasswordData,
  SignInCheckoutOnePage,
  SignInData,
  User,
} from "./types/auth-context";

export const signOutAux = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  localStorage.removeItem("user_id");
  localStorage.removeItem("cover_image");
  localStorage.removeItem("photo");

  cookie.remove("token");
  cookie.remove("username");

  Router.push("/dashboard");
};

export const setCookies = (parseToken: string, username: string) => {
  cookie.set("token", JSON.stringify(parseToken), {
    expires: 30, // 30 days
  });
  cookie.set("username", JSON.stringify(username), {
    expires: 30, // 30 days
  });
};

const INVALID_PASSWORD_MESSAGE_FROM_API = "Senha inválida.";
const INVALID_PASSWORD_MESSAGE = "Credenciais inválidas.";

const AuthContext = createContext({} as AuthContextProps);

export function AuthContextProvider({ children }: AuthContextData) {
  const router = useRouter();
  const mainPage = useMainPage();
  const queries = router.query;
  const auth_token = String(queries?.auth_token);
  const auth_username = String(queries?.auth_username);

  const [user, setUser] = useState<User | null>(null);
  const [comments, setComments] = useState<IComments>({} as IComments);
  const [followers, setFollowers] = useState<IFollowers>({} as IFollowers);
  const [following, setFollowing] = useState<IFollowing>({} as IFollowing);
  const [lastTopics, setLastTopics] = useState<ILastTopics>({} as ILastTopics);
  const [collections, setCollections] = useState<ICollections>(
    {} as ICollections,
  );

  const [loggedUser, setLoggedUser] = useState<User | null>(null);
  const [isProfileOwner, setIsProfileOwner] = useState<boolean>(false);
  const [emailConfirmed, setEmailConfirmed] = useState<boolean>(null);
  const [isLoadingUser, setIsLoadingUser] = useState<boolean>(false);
  const { setState } = useGlobalContext();

  const [userCoins, setUserCoins] = useState<number>(loggedUser?.total_coins);
  const [userPoints, setUserPoints] = useState<number>(
    loggedUser?.total_points,
  );
  const [
    shouldCalculateUserPositionInRanking,
    setShouldCalculateUserPositionInRanking,
  ] = useState(false);

  const isAuthenticated = !!loggedUser;

  const { getRole } = useRoleSlug();

  const [rolesUser, setRolesUser] = useState([]);

  const fetchRole = async (slug: string) => {
    try {
      const role = await getRole(slug);
      setRolesUser(role?.permission_slugs ?? []);
    } catch (error) {
      console.error("Failed to fetch role:", error);
      captureException(error);
      captureMessage("Failed to fetch role");

      openNotificationWithIcon(
        "error",
        "Não foi possível carregar suas permissões no momento. Por favor, tente acessar novamente mais tarde.",
      );
    }
  };

  useEffect(() => {
    if (loggedUser || !setIsLoadingUser) return;

    const { token, username: stringfiedUsername } = parseCookies();

    const hasUserTokenSaved = token && stringfiedUsername;
    if (!hasUserTokenSaved) return;

    const username = JSON.parse(stringfiedUsername || "");

    const loadUser = async () => {
      try {
        setIsLoadingUser(true);
        const result = await recoverUserInformation(username, api);

        setLoggedUser(result?.data);

        const roleSlug = result?.data?.console_role?.slug;
        if (roleSlug) await fetchRole(roleSlug);
      } catch (error) {
        console.error("loadUser:", error);
        captureException(error);
        captureMessage("loadUser");
      } finally {
        setIsLoadingUser(false);
      }
    };

    loadUser();
  }, [api, setIsLoadingUser]);

  useEffect(() => {
    if (loggedUser) return;

    const hasUsernameToSearch =
      !!auth_username && auth_username !== "undefined";
    if (!(auth_token && hasUsernameToSearch)) return;

    const loadUser = async () => {
      try {
        setIsLoadingUser(true);
        const response = await recoverUserInformation(auth_username, api);

        if (!response) return;

        setTimeout(async () => {
          setCookies(auth_token, auth_username);
          setLoggedUser(response?.data);
          sessionStorage.setItem("cameFromApp", "1");

          const roleSlug = response?.data?.console_role?.slug;
          if (roleSlug) await fetchRole(roleSlug);
        }, 2000);
      } catch (error) {
        console.error("loadUser:", error);
        captureException(error);
        captureMessage("loadUser");
      } finally {
        setIsLoadingUser(false);
      }
    };

    loadUser();
  }, [auth_token, auth_username]);

  function updateUserProfileInfo({
    user,
    followers,
    following,
    lastTopics,
    collections,
    comments,
  }: IUpdateUserProfileInfo) {
    setUser(user);
    setFollowers(followers);
    setFollowing(following);
    setLastTopics(lastTopics);
    setCollections(collections);
    setComments(comments);
  }

  function signInCheckoutOnePage({ token, user }: SignInCheckoutOnePage): void {
    setState((prev) => ({
      ...prev,
      user: {
        ...prev.user,
        loggedUser: user,
      },
    }));

    const parseToken = token?.split("|")[1];
    setCookies(parseToken, user.username);

    setLoggedUser(user);
  }

  async function signIn({ email, password }: SignInData) {
    try {
      const response = await signInRequest({
        email,
        password,
      });

      const token = response.token;
      const data = response?.data;

      if (response.message) {
        if (response?.message === INVALID_PASSWORD_MESSAGE_FROM_API) {
          response.message = INVALID_PASSWORD_MESSAGE;
        }

        return response.message;
      }

      if (response.data) {
        setState((prev) => ({
          ...prev,
          modal: {
            ...prev.modal,
            open: false,
            shouldCallbackFunction: true,
          },
        }));

        const parseToken = token?.plainTextToken?.split("|")[1];
        setCookies(parseToken, data.username);
        setLoggedUser(response?.data);

        const roleSlug = response?.data?.console_role?.slug;
        if (roleSlug) await fetchRole(roleSlug);

        if (router?.asPath?.includes("/401")) {
          router?.push(`/${mainPage}`);
        }
      }
    } catch (error) {
      console.log("[AuthContext]: an error occurred in signIn().");
      captureException(error);
    }
  }

  function signOut() {
    setLoggedUser(null);
    cookie.remove("token");
    cookie.remove("username");
    cookie.remove("organization");
    cookie.remove("statementPage", { path: "/" });
    cookie.remove("statementPage", { path: "/cp" });

    router.reload();
    sessionStorage.removeItem("courses_landing");

    removeAlreadyShowedLostPlaceInPodiumModalFromCookies();
    removeAlreadyShowedClimbedPodiumModalFromCookies();
  }

  async function signInWithUserToken(token: string): Promise<string> {
    try {
      const response = await api.post("auth-factory-token", {
        token,
      });

      if (response.data?.data) {
        const parseToken = response.data.token?.plainTextToken.split("|")[1];
        const username = response.data.data.username;

        setCookies(parseToken, username);
        setLoggedUser(response.data?.data);

        const roleSlug = response?.data?.data?.console_role?.slug;

        return roleSlug;
      }
    } catch (err) {
      console.log("Erro (signInWithUserToken): ", err);
      captureException(err);
      captureMessage("Erro (signInWithUserToken)");

      openNotificationWithIcon(
        "error",
        "Não foi possível realizar o login com este token. Por favor, tente novamente mais tarde.",
      );

      return null;
    }
  }

  async function register(data: RegisterData) {
    const { email, password, name, ...rest } = data;
    const splitedName = name
      .split(" ")
      .filter((split) => split !== "" && split !== " ");

    const [firstName] = splitedName;
    const lastName = splitedName.length > 1 ? splitedName.reverse()[0] : "";

    const response = await registerRequest({
      email,
      password,
      first_name: firstName,
      last_name: lastName,
      display_name: name,
      ...rest,
    });

    if (response?.type === "success") {
      if (response?.attrs) {
        const { token, user } = response.attrs;

        const parseToken = token?.plainTextToken.split("|")[1];
        setCookies(parseToken, user.username);
        setLoggedUser(user);
      }

      return response;
    } else {
      return response[Object.keys(response)[0]] || "fail";
    }
  }

  async function updateUser(data: RegisterData) {
    const { name, ...rest } = data;
    const splitedName = name
      .split(" ")
      .filter((split) => split !== "" && split !== " ");

    const [firstName] = splitedName;
    const lastName = splitedName.length > 1 ? splitedName.reverse()[0] : "";

    const response = await updateUserRequest({
      first_name: firstName,
      last_name: lastName,
      display_name: name,
      ...rest,
    });

    if (response?.attrs?.user && response?.type === "success") {
      if (response.attrs) {
        const { user } = response.attrs;

        setLoggedUser(user);
      }

      return response;
    } else {
      return response[Object.keys(response)[0]] || "fail";
    }
  }

  async function registerWithoutPassword(data: RegisterWithoutPassword) {
    const response = await registerWithoutPasswordRequest(data);

    if (!response?.errors && response?.attrs) {
      if (response.attrs) {
        const { token, user } = response.attrs;

        const parseToken = token?.plainTextToken.split("|")[1];
        setCookies(parseToken, user.username);
        setLoggedUser(user);
      }

      return "success";
    } else {
      return response[Object.keys(response)[0]] || "fail";
    }
  }

  async function resetPassword({ passwords, token }: ResetPasswordData) {
    try {
      const session = await resetPasswordRequest({ passwords, token });

      if (session.token) {
        setLoggedUser(session.data);
        const parseToken = session.token?.plainTextToken.split("|")[1];

        setCookies(parseToken, session.data.username);

        return "success";
      } else {
        return session;
      }
    } catch (error: any) {
      return error?.response?.data?.message;
    }
  }

  async function firstAccess({
    password,
    confirmPassword,
    token,
  }: FirstAccess) {
    try {
      const session = await firstAccessRequest({
        password,
        confirmPassword,
        token,
      });

      if (session.token) {
        setLoggedUser(session.data);
        const parseToken = session.token?.plainTextToken.split("|")[1];

        setCookies(parseToken, session.data.username);

        return "success";
      } else {
        return session;
      }
    } catch (error: any) {
      return error?.response?.data?.message;
    }
  }

  async function confirmEmail(token: string) {
    try {
      const response = await emailTokenConfirmation({ token });
      if (response) {
        const user = response?.attrs?.user;
        const token = response?.attrs?.token;

        if (user && token) {
          setEmailConfirmed(true);
          const parseToken = token.plainTextToken.split("|")[1];
          setLoggedUser(user);

          cookie.set("token", JSON.stringify(parseToken), { expires: 30 });
          cookie.set("username", JSON.stringify(user?.username), {
            expires: 30,
          });
          return "success";
        }
      }
    } catch (error: any) {
      setEmailConfirmed(false);
      return error?.response?.data?.message;
    }
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        signIn,
        signOut,
        register,
        updateUser,
        registerWithoutPassword,
        firstAccess,
        resetPassword,
        confirmEmail,
        setIsProfileOwner,
        updateUserProfileInfo,
        setLoggedUser,
        emailConfirmed,
        signInWithUserToken,
        isLoadingUser,

        userCoins,
        setUserCoins,
        userPoints,
        setUserPoints,
        shouldCalculateUserPositionInRanking,
        setShouldCalculateUserPositionInRanking,

        isAuthenticated,
        isProfileOwner,
        signInCheckoutOnePage,
        loggedUser,
        followers,
        following,
        lastTopics,
        collections,
        comments,

        rolesUser,
        setRolesUser,
        fetchRole,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
