import nookies, { destroyCookie, parseCookies } from "nookies";

import PodiumFirstPlaceIcon from "shared/components/molecules/Gamification/assets/icons/PodiumFirstPlaceIcon";
import PodiumSecondPlaceIcon from "shared/components/molecules/Gamification/assets/icons/PodiumSecondPlaceIcon";
import PodiumThirdPlaceIcon from "shared/components/molecules/Gamification/assets/icons/PodiumThirdPlaceIcon";

import { captureException, captureMessage } from "@sentry/nextjs";

export const podiumImageMap = [
  <PodiumFirstPlaceIcon key={"PodiumFirstPlaceIcon"} />,
  <PodiumSecondPlaceIcon key={"PodiumSecondPlaceIcon"} />,
  <PodiumThirdPlaceIcon key={"PodiumThirdPlaceIcon"} />,
];

export const podiumPlaceName = ["primeiro", "segundo", "terceiro"];

const cookieKey = "alreadyShowedClimbedPodiumModalInCookies";

export const saveAlreadyShowedClimbedPodiumModalInCookies = (value: boolean) =>
  nookies.set(null, cookieKey, value ? "true" : "false");

export const getAlreadyShowedClimbedPodiumModalFromCookies = (): boolean => {
  let result = false;

  try {
    const { alreadyShowedClimbedPodiumModalInCookies } = parseCookies();

    result = alreadyShowedClimbedPodiumModalInCookies === "true";
  } catch (error) {
    console.log(
      "Erro (getAlreadyShowedClimbedPodiumModalFromCookies): ",
      error,
    );
    captureMessage("Erro (getAlreadyShowedClimbedPodiumModalFromCookies)");
    captureException(error);
  }

  return result;
};

export const removeAlreadyShowedClimbedPodiumModalFromCookies = () =>
  destroyCookie(null, cookieKey);
