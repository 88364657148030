const PodiumSecondPlaceIcon = () => {
  return (
    <svg
      width="156"
      height="136"
      viewBox="0 0 156 136"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M47.4576 13.5742C47.7595 13.4126 48.0967 13.3284 48.4391 13.329L109.498 13.4266C109.856 13.4272 110.208 13.5203 110.519 13.697L130.875 25.253H25.625L47.4576 13.5742Z"
        fill="#BDC0C6"
      />
      <g filter="url(#filter0_d_10027_44967)">
        <path
          d="M25.625 25.2539H130.875V108.21C130.875 109.355 129.946 110.283 128.801 110.283H27.6989C26.5535 110.283 25.625 109.355 25.625 108.21V25.2539Z"
          fill="url(#paint0_linear_10027_44967)"
        />
        <g filter="url(#filter1_d_10027_44967)">
          <rect
            x="63.7324"
            y="53.25"
            width="29.0345"
            height="29.0345"
            rx="2.07389"
            fill="#798092"
            shapeRendering="crispEdges"
          />
          <path
            d="M71.9835 72.2656V70.887L75.2048 67.9043C75.4788 67.6391 75.7086 67.4005 75.8942 67.1884C76.0827 66.9763 76.2256 66.7686 76.3228 66.5654C76.42 66.3592 76.4686 66.1367 76.4686 65.8981C76.4686 65.633 76.4082 65.4047 76.2874 65.2132C76.1667 65.0188 76.0017 64.87 75.7925 64.7669C75.5834 64.6609 75.3462 64.6078 75.0811 64.6078C74.8042 64.6078 74.5626 64.6638 74.3564 64.7758C74.1502 64.8877 73.9911 65.0482 73.8792 65.2574C73.7672 65.4666 73.7113 65.7155 73.7113 66.0042H71.8951C71.8951 65.4121 72.0292 64.898 72.2973 64.462C72.5653 64.026 72.9409 63.6887 73.4241 63.4501C73.9072 63.2115 74.4639 63.0922 75.0944 63.0922C75.7425 63.0922 76.3066 63.2071 76.7868 63.4369C77.2699 63.6637 77.6455 63.9789 77.9136 64.3825C78.1816 64.7861 78.3157 65.2486 78.3157 65.77C78.3157 66.1117 78.2479 66.449 78.1124 66.7819C77.9798 67.1148 77.7427 67.4845 77.401 67.891C77.0593 68.2946 76.5776 68.7792 75.956 69.3448L74.6348 70.6395V70.7014H78.435V72.2656H71.9835ZM79.9363 65.9202V65.4518C79.9363 65.0306 80.0246 64.6579 80.2014 64.3339C80.3781 64.0069 80.6285 63.7506 80.9526 63.565C81.2796 63.3794 81.6625 63.2866 82.1015 63.2866C82.7731 63.2866 83.3019 63.4899 83.6878 63.8964C84.0767 64.3029 84.2711 64.8214 84.2711 65.4518V65.9202C84.2711 66.3385 84.1842 66.7097 84.0104 67.0338C83.8366 67.3549 83.5891 67.6082 83.268 67.7938C82.9469 67.9764 82.564 68.0678 82.1192 68.0678C81.4445 68.0678 80.9113 67.8674 80.5195 67.4668C80.1307 67.0632 79.9363 66.5477 79.9363 65.9202ZM81.0277 65.4518V65.9202C81.0277 66.2826 81.119 66.583 81.3017 66.8217C81.4843 67.0573 81.7568 67.1752 82.1192 67.1752C82.4756 67.1752 82.7407 67.0573 82.9145 66.8217C83.0913 66.583 83.1797 66.2826 83.1797 65.9202V65.4518C83.1797 65.0983 83.0913 64.7993 82.9145 64.5548C82.7378 64.3103 82.4668 64.1881 82.1015 64.1881C81.748 64.1881 81.4799 64.3103 81.2973 64.5548C81.1176 64.7993 81.0277 65.0983 81.0277 65.4518Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_10027_44967"
          x="0.7383"
          y="0.367207"
          width="155.023"
          height="134.805"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="12.4433" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.580392 0 0 0 0 0.611765 0 0 0 0 0.686275 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_10027_44967"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_10027_44967"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_10027_44967"
          x="38.8457"
          y="28.3633"
          width="78.8076"
          height="78.8086"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="12.4433" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_10027_44967"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_10027_44967"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_10027_44967"
          x1="25.8589"
          y1="25.2539"
          x2="144.425"
          y2="82.889"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A0A7BA" />
          <stop offset="1" stopColor="#747C8E" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default PodiumSecondPlaceIcon;
