import { parseCookies } from "nookies";

import { decodeOrganizationFromCookies } from "pages/[tenant]/browse/utils/cachedBillboardOrganizationContentUtils";

import {
  FirstAccess,
  RegisterWithoutPassword,
  ResetPasswordData,
  SignInData,
  SignUpRequestData,
  UpdateUserRequest,
} from "shared/providers/context/types/auth-context";

import * as Sentry from "@sentry/nextjs";

import api from "../api";
import getToken from "./getToken";

export async function signInRequest(data: SignInData) {
  try {
    const { organization: encodedOrganization } = parseCookies();
    const organization = decodeOrganizationFromCookies(encodedOrganization);

    const response = await api.post(
      "login",
      {
        ...data,
      },
      {
        params: {
          org: organization?.id,
        },
      },
    );

    return response?.data;
  } catch (error: any) {
    return error?.response?.data || error;
  }
}

export async function registerRequest(data: SignUpRequestData) {
  try {
    const { organization: encodedOrganization } = parseCookies();
    const organization = decodeOrganizationFromCookies(encodedOrganization);

    const response = await api.post(
      "register",
      {
        ...data,
      },
      {
        params: {
          org: organization?.id,
        },
      },
    );
    return response?.data;
  } catch (error: any) {
    return error.response.data;
  }
}

export async function updateUserRequest(data: UpdateUserRequest) {
  try {
    const response = await api.post("user/update", {
      ...data,
    });
    return response?.data;
  } catch (error: any) {
    return error.response.data;
  }
}

export async function registerWithoutPasswordRequest(
  data: RegisterWithoutPassword,
) {
  const headers = {
    "X-Requested-With": "XMLHttpRequest",
  };

  try {
    const response = await api.post(
      "user/create-without-password",
      { ...data },
      {
        headers,
      },
    );

    return response?.data;
  } catch (error) {
    return error?.response?.data || error;
  }
}

const getOrgParamFromURL = (): boolean => {
  let dontVerifyOrgParam = false;

  try {
    const params = new URLSearchParams(window.location.search);

    dontVerifyOrgParam = params.get("dontVerifyOrg") === "true";
  } catch (error) {
    console.log("Erro (getOrgParamFromURL): ", error);
    Sentry.captureException(error);
    Sentry.captureMessage("Erro (getOrgParamFromURL)");
  }

  return dontVerifyOrgParam;
};

export async function recoverUserInformation(username: string, api) {
  if (!(username && api)) return null;

  try {
    const { organization: encodedOrganization } = parseCookies();
    const organization = decodeOrganizationFromCookies(encodedOrganization);

    const response = await api.get(`user-profile/${username}`, {
      params: {
        org: organization?.id,
        dontVerifyOrg: getOrgParamFromURL(),
      },
    });
    return response.data;
  } catch (error: any) {
    Sentry.captureException(error);
    console.log("Error (recoverUserInformation): ", error);
    return error?.response?.data || error;
  }
}

export async function resetPasswordRequest({
  token,
  passwords,
}: ResetPasswordData) {
  const { password, confirmPassword } = passwords;

  if (password === confirmPassword) {
    try {
      const { organization: encodedOrganization } = parseCookies();
      const organization = decodeOrganizationFromCookies(encodedOrganization);

      const response = await api.post(
        "user/password/newpassword",
        {
          password,
          token,
        },
        {
          params: {
            org: organization?.id,
          },
        },
      );

      return response.data;
    } catch (error: any) {
      return error?.response?.data.message;
    }
  } else {
    return "Password and ConfirmPassword must be the same";
  }
}

export async function firstAccessRequest({
  confirmPassword,
  password,
  token: newToken,
}: FirstAccess) {
  const token = getToken();

  const headers = {
    Authorization: "Bearer " + token,
    "X-Requested-With": "XMLHttpRequest",
  };

  const data = {
    password,
    isFirstAccess: true,
  };

  if (newToken) {
    data["token"] = newToken;
  } else {
    data["headers"] = headers;
  }

  if (password === confirmPassword) {
    try {
      const { organization: encodedOrganization } = parseCookies();
      const organization = decodeOrganizationFromCookies(encodedOrganization);

      const response = await api.post("user/password/newpassword", data, {
        params: {
          org: organization?.id,
        },
      });

      return response?.data;
    } catch (error: any) {
      return error?.response?.data.message;
    }
  } else {
    return "Password and ConfirmPassword must be the same";
  }
}
