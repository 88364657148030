const PodiumFirstPlaceIcon = () => {
  return (
    <svg
      width="157"
      height="177"
      viewBox="0 0 157 177"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M47.6654 13.3281L110.309 13.4283L131.312 26.3521H25.1875L47.6654 13.3281Z"
        fill="#FFD84E"
      />
      <g filter="url(#filter0_d_10027_44889)">
        <path
          d="M25.1875 25.3516H131.312V149.251C131.312 150.406 130.376 151.342 129.221 151.342H27.2786C26.1237 151.342 25.1875 150.406 25.1875 149.251V25.3516Z"
          fill="url(#paint0_linear_10027_44889)"
        />
        <g filter="url(#filter1_d_10027_44889)">
          <rect
            x="60.4746"
            y="70.3125"
            width="35.5493"
            height="35.5493"
            rx="2.09113"
            fill="#DBAB00"
            shapeRendering="crispEdges"
          />
          <path
            d="M76.0284 82.4193V94.5859H73.456V84.861H73.3847L70.5985 86.6076V84.3263L73.6105 82.4193H76.0284ZM79.0745 86.0551V85.4253C79.0745 84.859 79.1933 84.358 79.4309 83.9223C79.6685 83.4827 80.0052 83.1382 80.4408 82.8887C80.8804 82.6392 81.3953 82.5144 81.9854 82.5144C82.8884 82.5144 83.5993 82.7877 84.1181 83.3342C84.6409 83.8808 84.9023 84.5778 84.9023 85.4253V86.0551C84.9023 86.6175 84.7855 87.1165 84.5518 87.5521C84.3181 87.9838 83.9855 88.3244 83.5538 88.5739C83.1221 88.8195 82.6072 88.9423 82.0092 88.9423C81.1022 88.9423 80.3854 88.6729 79.8586 88.1343C79.3359 87.5917 79.0745 86.8986 79.0745 86.0551ZM80.5418 85.4253V86.0551C80.5418 86.5422 80.6646 86.9462 80.9101 87.267C81.1557 87.5838 81.522 87.7422 82.0092 87.7422C82.4884 87.7422 82.8448 87.5838 83.0785 87.267C83.3161 86.9462 83.435 86.5422 83.435 86.0551V85.4253C83.435 84.9501 83.3161 84.5481 83.0785 84.2194C82.8409 83.8907 82.4765 83.7263 81.9854 83.7263C81.5102 83.7263 81.1498 83.8907 80.9042 84.2194C80.6626 84.5481 80.5418 84.9501 80.5418 85.4253Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_10027_44889"
          x="0.0939045"
          y="0.257967"
          width="156.312"
          height="176.179"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="12.5468" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.976471 0 0 0 0 0.784314 0 0 0 0 0.101961 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_10027_44889"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_10027_44889"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_10027_44889"
          x="35.381"
          y="45.2189"
          width="85.736"
          height="85.738"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="12.5468" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_10027_44889"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_10027_44889"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_10027_44889"
          x1="25.4233"
          y1="25.3516"
          x2="157.939"
          y2="110.054"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFCE20" />
          <stop offset="1" stopColor="#DDAC00" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default PodiumFirstPlaceIcon;
