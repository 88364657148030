import nookies, { destroyCookie, parseCookies } from "nookies";

import { captureException, captureMessage } from "@sentry/nextjs";

const cookieKey = "alreadyShowedLostPlaceInPodiumModalInCookies";

export const saveAlreadyShowedShowLostPlaceInPodiumModalInCookies = (
  value: boolean,
) => nookies.set(null, cookieKey, value ? "true" : "false");

export const getAlreadyShowedLostPlaceInPodiumModalFromCookies =
  (): boolean => {
    let result = false;

    try {
      const { alreadyShowedLostPlaceInPodiumModalInCookies } = parseCookies();

      result = alreadyShowedLostPlaceInPodiumModalInCookies === "true";
    } catch (error) {
      console.log(
        "Erro (getAlreadyShowedLostPlaceInPodiumModalFromCookies): ",
        error,
      );
      captureMessage(
        "Erro (getAlreadyShowedLostPlaceInPodiumModalFromCookies)",
      );
      captureException(error);
    }

    return result;
  };

export const removeAlreadyShowedLostPlaceInPodiumModalFromCookies = () =>
  destroyCookie(null, cookieKey);
